"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Layout = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var calculateRows = function calculateRows(props) {
  var n = props.n;
  var row = Math.ceil(n / 2);
  var rowCount = Math.ceil(row / 3 * 28);
  return rowCount;
};
var Layout = exports.Layout = _styledComponents["default"].div.withConfig({
  displayName: "layout__Layout",
  componentId: "sc-1cahaoz-0"
})(["width:100%;display:grid;grid-gap:5px;grid-template-columns:repeat(2,1fr);grid-template-rows:repeat(", ",24px);grid-auto-flow:dense;justify-content:center;padding:0 5px;--small:span 9;--large:span 10;& > *{grid-row:auto / var(--small);}& > *:nth-child(2n -1){grid-column:1 / span 1;}& > *:nth-child(2n){grid-column:2 / span 1;}& > *:nth-child(6n - 5){grid-row:auto / var(--large);}& > *:nth-child(6n){grid-row:auto / var(--large);}"], calculateRows);
Layout.displayName = 'Layout';
Layout.propTypes = {
  n: _propTypes["default"].number.isRequired
};
var _default = exports["default"] = Layout;