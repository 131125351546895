"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
exports.formatUrlString = formatUrlString;
Object.defineProperty(exports, "getUrlStringHelper", {
  enumerable: true,
  get: function get() {
    return _getUrlStringHelper["default"];
  }
});
Object.defineProperty(exports, "parseUrlString", {
  enumerable: true,
  get: function get() {
    return _parseUrlString["default"];
  }
});
var _getUrlStringHelper = _interopRequireDefault(require("./get-url-string-helper"));
var _parseUrlString = _interopRequireDefault(require("./parse-url-string"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function formatUrlString(args) {
  var _args$protocol = args.protocol,
    protocol = _args$protocol === void 0 ? '' : _args$protocol,
    _args$prefix = args.prefix,
    prefix = _args$prefix === void 0 ? '' : _args$prefix,
    _args$tail = args.tail,
    tail = _args$tail === void 0 ? '' : _args$tail;
  return [protocol, prefix, tail].join('');
}
var _default = exports["default"] = {
  parse: _parseUrlString["default"],
  format: formatUrlString,
  getHelper: _getUrlStringHelper["default"]
};