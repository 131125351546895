import React from 'react';
import withSocialNetwork from 'hocs/with-social-network';
import {
  SocialAppleMusicIcon,
  SocialFacebookIcon,
  SocialGenericIcon,
  SocialInstagramIcon,
  SocialLinkedInIcon,
  SocialPinterestIcon,
  SocialSpotifyIcon,
  SocialSnapchatIcon,
  SocialSoundcloudIcon,
  SocialTwitterIcon,
  SocialYouTubeIcon,
  SocialTelephoneIcon,
  SocialTiktokIcon,
  SocialEmailIcon,
} from 'components/icons';

function SocialIcon({ url, socialNetwork, ...props }) {
  switch (socialNetwork) {
    case 'apple-music':
      return <SocialAppleMusicIcon {...props} width={32} height={32} />;

    case 'facebook':
      return <SocialFacebookIcon {...props} />;

    case 'instagram':
      return <SocialInstagramIcon {...props} />;

    case 'linkedin':
      return <SocialLinkedInIcon {...props} />;

    case 'tiktok':
      return <SocialTiktokIcon {...props} />;

    case 'snapchat':
      return <SocialSnapchatIcon {...props} />;

    case 'soundcloud':
      return <SocialSoundcloudIcon {...props} />;

    case 'pinterest':
      return <SocialPinterestIcon {...props} />;

    case 'spotify':
      return <SocialSpotifyIcon {...props} width={32} height={32} />;

    case 'twitter':
      return <SocialTwitterIcon {...props} />;

    case 'youtube':
      return <SocialYouTubeIcon {...props} />;

    case 'telephone':
      return <SocialTelephoneIcon {...props} />;

    case 'email':
      return <SocialEmailIcon {...props} />;

    default:
      return <SocialGenericIcon {...props} />;
  }
}

export default withSocialNetwork(SocialIcon);
