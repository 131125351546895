"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var countryCodeRegex = /^[A-Z]{2}$/;
var dialPrefixRegex = /^[0-9]{1,4}$/;
function InvalidPropError(propName, propValue, componentName) {
  return Error("Invalid prop `".concat(propName, "` supplied to\n    ").concat(componentName, ". Validation failed. The prop value\n    must be a string matching ").concat(countryCodeRegex.toString(), ",\n    but '").concat(propValue, "' was given.\n  "));
}
_propTypes["default"].countryCode = function (props, propName, componentName) {
  var value = props[propName];
  if (!countryCodeRegex.test(value)) {
    return new InvalidPropError(propName, value, componentName);
  }
};
_propTypes["default"].countryCodes = function (propValue, key, componentName, location, propFullName) {
  var value = propValue[key];
  if (!countryCodeRegex.test(value)) {
    return new InvalidPropError(propFullName, value, componentName);
  }
};
_propTypes["default"].dialPrefix = function (props, propName, componentName) {
  var value = props[propName];
  if (!dialPrefixRegex.test(value)) {
    return new InvalidPropError(propName, value, componentName);
  }
};
_propTypes["default"].dialPrefixes = function (propValue, key, componentName, location, propFullName) {
  var value = propValue[key];
  if (!dialPrefixRegex.test(value)) {
    return new InvalidPropError(propFullName, value, componentName);
  }
};
var _default = exports["default"] = _propTypes["default"];