"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.ViewHeaderLayout = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var ViewHeaderLayout = exports.ViewHeaderLayout = _styledComponents["default"].div.withConfig({
  displayName: "layout__ViewHeaderLayout",
  componentId: "sc-ecj1yv-0"
})(["display:flex;justify-content:center;align-items:center;flex-shrink:0;height:65px;background:inherit;text-align:center;box-shadow:0 0 3px rgba(0,0,0,0.15);"]);
ViewHeaderLayout.displayName = 'ViewHeaderLayout';
var _default = exports["default"] = ViewHeaderLayout;