import { validEmail, validPhoneNumber } from 'lib/validations';

export const SOCIAL_NETWORKS = [
  {
    id: 'facebook',
    title: 'Facebook',
    matcher: new RegExp('//(?:www.)?facebook.com'),
    template: 'https://www.facebook.com/:username',
    placeholder: 'Enter username',
  },
  {
    id: 'instagram',
    title: 'Instagram',
    matcher: new RegExp('//(?:www.)?instagram.com'),
    template: 'https://www.instagram.com/:username',
    placeholder: 'Enter username',
  },
  {
    id: 'linkedin',
    title: 'LinkedIn',
    matcher: new RegExp('//(?:www.)?linkedin.com'),
    template: ':username',
    placeholder: 'Enter URL',
  },
  {
    id: 'twitter',
    title: 'Twitter',
    matcher: new RegExp('//(?:www.)?twitter.com'),
    template: 'https://twitter.com/:username',
    placeholder: 'Enter username',
  },
  {
    id: 'pinterest',
    title: 'Pinterest',
    matcher: new RegExp('//(?:www.)?pinterest.com'),
    template: 'https://www.pinterest.com/:username',
    placeholder: 'Enter username',
  },
  {
    id: 'youtube',
    title: 'YouTube',
    matcher: new RegExp('//(?:www.)?youtube.com'),
    template: 'https://www.youtube.com/:username',
    placeholder: 'Enter username',
  },
  {
    id: 'tiktok',
    title: 'Tiktok',
    matcher: new RegExp('//(?:www.)?tiktok.com'),
    template: 'https://www.tiktok.com/@:username',
    placeholder: 'Enter username',
  },
  {
    id: 'snapchat',
    title: 'Snapchat',
    matcher: new RegExp('//(?:www.)?snapchat.com'),
    template: 'https://www.snapchat.com/add/:username',
    placeholder: 'Enter username',
  },
  {
    id: 'soundcloud',
    title: 'SoundCloud',
    matcher: new RegExp('//(?:www.)?soundcloud.com'),
    template: 'https://www.soundcloud.com/:username',
    placeholder: 'Enter username',
  },
  {
    id: 'apple-music',
    title: 'Apple Music',
    matcher: new RegExp('//itunes.apple.com'),
    template: ':username',
    placeholder: 'Enter URL',
  },
  {
    id: 'spotify',
    title: 'Spotify',
    matcher: new RegExp('spotify.com'),
    template: ':username',
    placeholder: 'Enter URL',
  },
  {
    id: 'email',
    title: 'Email',
    matcher: new RegExp(validEmail),
    template: ':username',
    placeholder: 'Enter email address',
  },
  {
    id: 'telephone',
    title: 'Phone Number',
    matcher: new RegExp(validPhoneNumber),
    template: ':username',
    placeholder: 'Enter phone number',
  },
];

export const networkFromUrl = (url) => {
  const match = SOCIAL_NETWORKS.filter((network) => url.match(network.matcher)).pop();
  return match;
};

export const urlToId = (url) => {
  const network = networkFromUrl(url);
  return network ? network.id : '';
};

export const urlToUsername = (url) => {
  if (!url) return '';

  const network = networkFromUrl(url);
  if (!network) return url;

  const networkHost = network.template.replace(':username', '');

  if (networkHost) {
    return url.split(networkHost)[1];
  }

  // If the entered value is a full URL return the entire thing.
  return url;
};

export const usernameToUrl = (networkId, username) => {
  if (!username) return '';
  if (!networkId) return username;
  const network = SOCIAL_NETWORKS.find((network) => network.id === networkId);
  return network.template.replace(':username', username);
};

export default {
  networkFromUrl,
  urlToId,
  urlToUsername,
  usernameToUrl,
};
