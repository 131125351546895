"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.GridSpace = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var GridSpace = exports.GridSpace = _styledComponents["default"].div.withConfig({
  displayName: "grid-space__GridSpace",
  componentId: "sc-eez7s-0"
})(["display:flex;"]);
GridSpace.displayName = 'GridSpace';
var _default = exports["default"] = GridSpace;