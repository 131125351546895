"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _constants = require("./constants");
function getUrlStringProtocol(str) {
  var match = str.match(_constants.REGEXS.PROTOCOLS);
  return match && match[1];
}
function getUlrStringPrefix(str) {
  var match = str.match(_constants.REGEXS.TEL_PREFIX);
  return match && match[0];
}
function parseUrlString() {
  var urlString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  urlString = urlString.trim();
  var protocol = getUrlStringProtocol(urlString) || '';
  urlString = urlString.replace(protocol, '');
  var prefix = getUlrStringPrefix(urlString) || '';
  urlString = urlString.replace(prefix, '');
  return {
    protocol: protocol.toLowerCase(),
    prefix: prefix,
    tail: urlString.replace(_constants.REGEXS.LEADING_SLASHES, '').trim()
  };
}
var _default = exports["default"] = parseUrlString;