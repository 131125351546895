"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.CountrySelectSearch = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var CountrySelectSearch = exports.CountrySelectSearch = _styledComponents["default"].input.withConfig({
  displayName: "search__CountrySelectSearch",
  componentId: "sc-b90kv1-0"
})(["display:block;border:1px solid #eceff1;padding:0.75rem 1rem;margin-bottom:0.375rem;"]);
CountrySelectSearch.propTypes = {
  placeholder: _propTypes["default"].string,
  onChange: _propTypes["default"].func.isRequired
};
CountrySelectSearch.defaultProps = {
  placeholder: 'Search'
};
var _default = exports["default"] = CountrySelectSearch;