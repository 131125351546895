"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _constants = require("./constants");
function getUrlStringHelper() {
  var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  str = str.trim();
  /**
    If a string starts with `['mailto:', 'http:', 'https:']`, assume the user
    knows what she's doing, or pasted in a url; don't use a helper
   */
  if (_constants.REGEXS.EMAIL_PROTOCOL.test(str)) return _constants.HELPERS.NONE;
  if (_constants.REGEXS.WEB_PROTOCOL.test(str)) return _constants.HELPERS.NONE;

  /**
    DO use a helper if the protocol is `tel:` though, we have our own special
    prefix syntax, so we need to provide some supporting UI
   */
  if (_constants.REGEXS.TEL_PROTOCOL.test(str)) return _constants.HELPERS.TEL;

  /**
    Next is prefixes. Pretty straight forward, if no protocol exists, then
    we'll help the user out based on what the string prefix looks like
   */
  if (_constants.REGEXS.TEL_PREFIX.test(str)) return _constants.HELPERS.TEL;
  if (_constants.REGEXS.WEB_PREFIX.test(str)) return _constants.HELPERS.WEB;

  /**
    Then try to match against the whole string
    The order is important, EMAIL is just a special
    type of WEB, so if it matches, return immediately
   */
  if (_constants.REGEXS.TEL_TEST.test(str)) return _constants.HELPERS.TEL;
  if (_constants.REGEXS.EMAIL_TEST.test(str)) return _constants.HELPERS.EMAIL;
  if (_constants.REGEXS.WEB_TEST.test(str)) return _constants.HELPERS.WEB;

  /**
    If nothing matches, we'll treat it as any other input
   */
  return _constants.HELPERS.NONE;
}
var _default = exports["default"] = getUrlStringHelper;