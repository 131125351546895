"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var Button = _styledComponents["default"].button.withConfig({
  displayName: "button__Button",
  componentId: "sc-1v7lmc9-0"
})(["font-size:1em;font-weight:400;padding:0.5em 1em;background-color:transparent;color:currentColor;fill:currentColor;border:2px solid transparent;cursor:pointer;line-height:1.25;&:hover{outline:none;transform:translate3d(0,1px,0);}&:focus{outline:none;box-shadow:inset 0 -2px 0 0 rgb(52,21,82,0.25);}&:active{outline:none;box-shadow:inset 0 0 0 100vh rgb(52,21,82,0.25);transform:translate3d(0,1px,0);}"]);
Button.propTypes = {
  title: _propTypes["default"].string,
  children: _propTypes["default"].any.isRequired,
  onClick: _propTypes["default"].func,
  type: _propTypes["default"].string.isRequired
};
Button.defaultProps = {
  type: 'button',
  onClick: function onClick() {
    return 'Button on click';
  }
};
Button.displayName = 'Button';
var _default = exports["default"] = Button;