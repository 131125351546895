"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inputStyles = exports["default"] = exports.Tag = exports.Overlay = exports.OptionsPanel = exports.List = exports.Layout = exports.Flag = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _flag = _interopRequireDefault(require("@tap-bio/ui/elements/flag"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var inputStyles = exports.inputStyles = (0, _styledComponents.css)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding-left: 0.5em;\n  padding-right: 0.5em;\n  height: 3.125em;\n  border: 1px solid #e6e6e6;\n  background-color: #fafafa;\n  border-radius: 6px;\n  font-size: 1em;\n"])));
var Flag = exports.Flag = (0, _styledComponents["default"])(_flag["default"]).withConfig({
  displayName: "styled__Flag",
  componentId: "sc-1lbvjub-0"
})(["font-size:1.25em;margin:0 0.25em;"]);
var Tag = exports.Tag = _styledComponents["default"].span.withConfig({
  displayName: "styled__Tag",
  componentId: "sc-1lbvjub-1"
})(["background-color:#eceff1;font-size:1em;font-family:monospace;text-align:center;padding:1px 0.5em;border-radius:3px;"]);
var Layout = exports.Layout = _styledComponents["default"].div.withConfig({
  displayName: "styled__Layout",
  componentId: "sc-1lbvjub-2"
})(["display:", ";"], function (p) {
  return p.show ? 'flex' : 'none';
});
var OptionsPanel = exports.OptionsPanel = _styledComponents["default"].div.withConfig({
  displayName: "styled__OptionsPanel",
  componentId: "sc-1lbvjub-3"
})(["position:absolute;top:3em;line-height:1em;background:white;border-radius:3px;box-shadow:0 2px 10px rgba(0,0,0,0.2);padding:0.5em 1em;display:", ";flex-direction:column;"], function (p) {
  return p.show ? 'flex' : 'none';
});
var Overlay = exports.Overlay = _styledComponents["default"].div.withConfig({
  displayName: "styled__Overlay",
  componentId: "sc-1lbvjub-4"
})(["display:", ";position:fixed;top:0;left:0;bottom:0;right:0;background-color:rgba(0,0,0,0.5);"], function (p) {
  return p.show ? 'block' : 'none';
});
var List = exports.List = _styledComponents["default"].div.withConfig({
  displayName: "styled__List",
  componentId: "sc-1lbvjub-5"
})(["display:", ";max-height:14em;overflow-y:scroll;"], function (p) {
  return p.children ? 'block' : 'none';
});
var _default = exports["default"] = {
  Flag: Flag,
  Tag: Tag,
  inputStyles: inputStyles,
  Layout: Layout,
  OptionsPanel: OptionsPanel,
  Overlay: Overlay,
  List: List
};