const development = {
  domain: '3000.b4ff2185-13c6-4c25-9d0e-c5a88a7995dc.vpc.network',
  cdnHost: 'https://tap-bio-development.imgix.net',
  apiHost: 'https://3000.b4ff2185-13c6-4c25-9d0e-c5a88a7995dc.vpc.network',
  landingHost: 'https://5000.b4ff2185-13c6-4c25-9d0e-c5a88a7995dc.vpc.network',
  dashboardHost: 'https://4000.b4ff2185-13c6-4c25-9d0e-c5a88a7995dc.vpc.network',
  featuredProfiles: [
    { username: 'tap.bio', featuredText: 'Trending' },
    { username: 'tapbiodeveloper', featuredText: 'Trending' },
    { username: 'timothyfletcher', featuredText: 'Trending' },
    { username: 'christiannaths', featuredText: 'Featured' },
    { username: 'timothyfletcher_test' },
    { username: 'maddenwalker_dev', featuredText: 'Featured' },
    { username: 'maddenwalker' },
  ],
  google: {
    cloudStorageMediaBucket: 'tb-development-media',
  },
  instagram: {
    appId: process.env.REACT_APP_INSTAGRAM_APP_ID || '3595116747180026',
  },
  mailchimp: {
    clientId: '731742344703',
  },
  constantcontact: {
    clientId: 'sqbrzh8s9hu244egpd85yke2',
  },
  routes: {},
  stripe: {
    publishableKey: 'pk_test_0Sw9oY3CULgDl04tLMw8uPLG',
  },
  sentry: {
    publicDsn: '',
  },
};

export default development;
